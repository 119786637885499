import React from 'react';

const Heart = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="91.11"
    height="74.518"
    viewBox="0 0 91.11 74.518"
  >
    <defs>
      <clipPath id="clip-path-heart">
        <path id="Tracé_810" data-name="Tracé 810" d="M0,0H91.11V-74.518H0Z" fill="none" />
      </clipPath>
    </defs>
    <g id="Picto_hydratation" data-name="Picto hydratation" transform="translate(0 74.518)">
      <g id="Groupe_1034" data-name="Groupe 1034" clipPath="url(#clip-path-heart)">
        <g id="Groupe_1029" data-name="Groupe 1029" transform="translate(68.467 -71.159)">
          <path
            id="Tracé_805"
            data-name="Tracé 805"
            d="M0,0,.681-1.907A18.672,18.672,0,0,0-5.63-2.994,21.572,21.572,0,0,0-24.162,7.943l1.8.929,1.817-.893A21.6,21.6,0,0,0-39.271-3.359a18.658,18.658,0,0,0-5.918.956A21.2,21.2,0,0,0-59.958,18.066l2.025,0L-59.957,18v.079c.049,13.784,9.205,25.643,18.078,34.145A108.47,108.47,0,0,0-24.056,65.954l.417.245.6.037.593.037.52-.29A108.5,108.5,0,0,0-3.806,52.631C5.249,44.319,14.658,32.654,15,18.867l0-.485A21.186,21.186,0,0,0,.681-1.907L0,0-.682,1.907A17.105,17.105,0,0,1,10.956,18.382l0,.4c-.2,11.857-8.739,22.888-17.491,30.859a106.8,106.8,0,0,1-11.978,9.411c-1.631,1.1-2.987,1.96-3.93,2.535-.471.287-.839.5-1.087.649l-.366.21.988,1.768.125-2.021-.119-.007-.124,2.021L-22,62.462l-.361-.218A105.751,105.751,0,0,1-40.681,47.726c-8-8-15.266-18.539-15.227-29.652h-1.849l1.848.066v-.066h-1.849l1.848.066v-.071a17.123,17.123,0,0,1,12-16.63A14.611,14.611,0,0,1-39.271.691c6.513-.055,12.852,4.4,15.091,9.073l1.773,3.611L-20.562,9.8C-18.276,5.264-12.03,1-5.63,1.056a14.651,14.651,0,0,1,4.949.851Z"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1030" data-name="Groupe 1030" transform="translate(44.469 -63.482)">
          <path
            id="Tracé_806"
            data-name="Tracé 806"
            d="M0,0A41.512,41.512,0,0,0-3.349,5.707,29.925,29.925,0,0,0-6.723,18.393a16.483,16.483,0,0,0,.8,5.19,7.8,7.8,0,0,0,4.2,4.8,8.283,8.283,0,0,0,3.315.751l.1,0,2.024-.055-.11-4.048-2.024.055.006.213v-.214H1.581l.006.213v-.214a4.318,4.318,0,0,1-1.631-.384,3.7,3.7,0,0,1-2.035-2.383,12.433,12.433,0,0,1-.594-3.919A26.07,26.07,0,0,1,.288,7.488,41.213,41.213,0,0,1,2.361,3.759l.676-1.034.234-.337L4.465.753,1.194-1.635Z"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1031" data-name="Groupe 1031" transform="translate(44.469 -61.093)">
          <path
            id="Tracé_807"
            data-name="Tracé 807"
            d="M0,0,.235.337C1.38,1.951,6,9.4,5.944,16a12.463,12.463,0,0,1-.593,3.92,3.7,3.7,0,0,1-2.036,2.383,4.336,4.336,0,0,1-1.63.384v.252l.007-.251H1.685v.252l-2.018-.308-.112,4.049c2.042.057,2.078.057,2.13.057A8.252,8.252,0,0,0,5,25.99a7.8,7.8,0,0,0,4.2-4.8A16.483,16.483,0,0,0,9.994,16,29.933,29.933,0,0,0,6.621,3.318a41.4,41.4,0,0,0-3.35-5.706L2.078-4.024-1.194-1.636Z"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1032" data-name="Groupe 1032" transform="translate(44.408 -8.613)">
          <path
            id="Tracé_808"
            data-name="Tracé 808"
            d="M0,0,.645.939.027-.018,0,0,.645.939.027-.018a9.478,9.478,0,0,1-1.518.7c-3.29,1.292-12.545,3.881-33.264,3.878q-3.534,0-7.519-.105L-44.3,4.4l-.11,4.048,2.024.056q4.042.109,7.629.106C-5.788,8.6,1.752,3.69,2.294,3.337L3.963,2.19l-2.3-3.337Z"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1033" data-name="Groupe 1033" transform="translate(44.408 -5.276)">
          <path
            id="Tracé_809"
            data-name="Tracé 809"
            d="M0,0C.542.353,8.082,5.266,37.049,5.276q3.591,0,7.629-.107L46.7,5.114l-.11-4.048-2.024.055q-3.99.106-7.519.1C22.792,1.226,13.96,0,8.769-1.183a33.875,33.875,0,0,1-5.284-1.59,11.168,11.168,0,0,1-1.02-.471l-.181-.1-.017-.011L1.648-2.4l.646-.94-.027-.019L1.648-2.4.626-4.485l-2.3,3.338Z"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Heart;
